var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"match-height mt-4"},[_c('v-col',{attrs:{"md":"3","sm":"6","xs":"12"}},[_c('bond-statistics-card',{attrs:{"title":'Interest this month',"value":_vm.interestData.interest_this_month,"is-loading":_vm.isInterestDataLoading}})],1),_c('v-col',{attrs:{"md":"3","sm":"6","xs":"12"}},[_c('bond-statistics-card',{attrs:{"title":'Average monthly interest',"value":_vm.interestData.average_monthly_interest,"is-loading":_vm.isInterestDataLoading}})],1),_c('v-col',{attrs:{"md":"3","sm":"6","xs":"12"}},[_c('bond-statistics-card',{attrs:{"title":'Interest per annum',"value":_vm.interestData.interest_per_annum,"is-loading":_vm.isInterestDataLoading}})],1),_c('v-col',{attrs:{"md":"3","sm":"6","xs":"12"}},[_c('bond-statistics-card',{attrs:{"title":'Total amount invested',"value":_vm.interestData.total_investment,"is-loading":_vm.isInterestDataLoading}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"md":"7","sm":"12"}},[_vm._v(" Monthly Interest ")]),_c('v-spacer'),_c('v-col',{staticClass:"text-right",attrs:{"lg":"3","md":"2","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.monthlyInterestDataYears,"value":_vm.monthlyInterestDataYear,"item-text":"year","item-value":"year","outlined":"","dense":"","hide-details":""},on:{"change":_vm.handleYearChange}})],1)],1)],1),_c('apexchart',{attrs:{"type":"bar","height":"350","width":"100%","options":_vm.chartOptions,"series":_vm.monthlyInterestData}})],1)],1),_c('v-col',{attrs:{"id":"upcoming-interest","cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Upcoming Interest ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.upcomingInterestDataTableHeaders,"items":_vm.upcomingInterestData,"loading":_vm.isUpcomingInterestDataLoading,"items-per-page":5,"footer-props":{
          'items-per-page-options': [5, 10, 25, 50],
        }},scopedSlots:_vm._u([{key:"item.interest",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.interest_amount)))])]}},{key:"item.date",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.date)))])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Interest Paying Months ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.bondsDataHeaders,"items":_vm.bondsData,"loading":_vm.isBondsDataLoading,"items-per-page":10,"footer-props":{
          'items-per-page-options': [5, 10, 25, 50],
        }},scopedSlots:_vm._u([{key:"item.total_interest_per_annum",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.total_interest_per_annum)))])]}},{key:"item.semi_annual_interest",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatCurrency")((item.total_interest_per_annum / 2))))])]}},{key:"item.interest_paying_months",fn:function(ref){
        var item = ref.item;
return _vm._l((item.interest_paying_months),function(month,index){return _c('v-chip',{key:index,staticClass:"ma-2"},[_vm._v(" "+_vm._s(month)+" ")])})}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }