<template>
  <v-card>
    <v-card-title
      v-show="!isLoading"
      class="align-start"
    >
      <span class="font-weight-light">{{ title }}</span>
    </v-card-title>

    <v-card-text
      v-show="isLoading"
    >
      <bond-statistics-card-shimmer />
    </v-card-text>

    <v-card-text
      v-show="!isLoading"
    >
      <div class="d-flex align-center">
        <h1 class="text-3xl font-weight-medium">
          {{ value | formatCurrency }}
        </h1>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import BondStatisticsCardShimmer from '@/components/shimmers/BondStatisticsCardShimmer.vue'

export default {
  filters: {
    positive(value) {
      return Math.abs(value)
    },
  },
  components: {
    BondStatisticsCardShimmer,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
