<template>
  <v-row
    class="match-height mt-4"
  >
    <v-col
      md="3"
      sm="6"
      xs="12"
    >
      <bond-statistics-card
        :title="'Interest this month'"
        :value="interestData.interest_this_month"
        :is-loading="isInterestDataLoading"
      />
    </v-col>
    <v-col
      md="3"
      sm="6"
      xs="12"
    >
      <bond-statistics-card
        :title="'Average monthly interest'"
        :value="interestData.average_monthly_interest"
        :is-loading="isInterestDataLoading"
      />
    </v-col>
    <v-col
      md="3"
      sm="6"
      xs="12"
    >
      <bond-statistics-card
        :title="'Interest per annum'"
        :value="interestData.interest_per_annum"
        :is-loading="isInterestDataLoading"
      />
    </v-col>
    <v-col
      md="3"
      sm="6"
      xs="12"
    >
      <bond-statistics-card
        :title="'Total amount invested'"
        :value="interestData.total_investment"
        :is-loading="isInterestDataLoading"
      />
    </v-col>
    <v-col
      cols="12"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col
              md="7"
              sm="12"
            >
              Monthly Interest
            </v-col>
            <v-spacer />
            <v-col
              lg="3"
              md="2"
              sm="12"
              class="text-right"
            >
              <v-select
                :items="monthlyInterestDataYears"
                :value="monthlyInterestDataYear"
                item-text="year"
                item-value="year"
                outlined
                dense
                hide-details
                @change="handleYearChange"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-title>
        <apexchart
          type="bar"
          height="350"
          width="100%"
          :options="chartOptions"
          :series="monthlyInterestData"
        />
      </v-card>
    </v-col>
    <v-col
      id="upcoming-interest"
      cols="12"
    >
      <v-card>
        <v-card-title>
          Upcoming Interest
        </v-card-title>
        <v-data-table
          :headers="upcomingInterestDataTableHeaders"
          :items="upcomingInterestData"
          :loading="isUpcomingInterestDataLoading"
          :items-per-page="5"
          :footer-props="{
            'items-per-page-options': [5, 10, 25, 50],
          }"
          class="elevation-1"
        >
          <template v-slot:item.interest="{ item }">
            <span>{{ item.interest_amount | formatCurrency }}</span>
          </template>
          <template v-slot:item.date="{ item }">
            <span>{{ item.date | formatDate }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col
      cols="12"
    >
      <v-card>
        <v-card-title>
          Interest Paying Months
        </v-card-title>
        <v-data-table
          :headers="bondsDataHeaders"
          :items="bondsData"
          :loading="isBondsDataLoading"
          :items-per-page="10"
          :footer-props="{
            'items-per-page-options': [5, 10, 25, 50],
          }"
          class="elevation-1"
        >
          <template v-slot:item.total_interest_per_annum="{ item }">
            <span>{{ item.total_interest_per_annum | formatCurrency }}</span>
          </template>
          <template v-slot:item.semi_annual_interest="{ item }">
            <span>{{ (item.total_interest_per_annum / 2) | formatCurrency }}</span>
          </template>
          <template v-slot:item.interest_paying_months="{ item }">
            <v-chip
              v-for="(month, index) in item.interest_paying_months"
              :key="index"
              class="ma-2"
            >
              {{ month }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import BondStatisticsCard from '@/components/statistics-card/BondStatisticsCard.vue'

export default {
  components: {
    BondStatisticsCard,
    apexchart: VueApexCharts,
  },
  data: () => ({
    isInterestDataLoading: false,
    isMonthlyInterestDataLoading: false,
    isUpcomingInterestDataLoading: false,
    isBondsDataLoading: false,
    interestData: {
      interest_this_month: 0,
      average_monthly_interest: 0,
      interest_per_annum: 0,
      total_investment: 0,
    },
    monthlyInterestDataYear: new Date().getFullYear(),
    monthlyInterestDataYears: [new Date().getFullYear()],
    monthlyInterestData: [],
    bondsData: [],
    bondsDataHeaders: [
      {
        text: 'Bond',
        value: 'issue_number',
      },
      {
        text: 'Interest per annum',
        value: 'total_interest_per_annum',
      },
      {
        text: 'Semi-annual interest',
        value: 'semi_annual_interest',
      },
      {
        text: 'Interest paying months',
        value: 'interest_paying_months',
      },
    ],
    upcomingInterestData: [],
    upcomingInterestDataTableHeaders: [
      {
        text: 'Bond',
        value: 'issue_number',
      },
      {
        text: 'Interest',
        value: 'interest',
      },
      {
        text: 'Date',
        value: 'date',
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: 'category',
        categories: [],
      },
      yaxis: {
        title: {
          text: 'Interest',
        },
      },
      series: [{
        name: 'Interest',
        data: [],
      }],
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        y: {
          formatter(value) {
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'KES',
              minimumFractionDigits: 0,
            })

            return formatter.format(value)
          },
        },
      },
    },

  }),
  mounted() {
    this.getInterestData()
    this.getMonthlyInterestData()
    this.getInterestDataYears()
    this.getUpcomingInterestData()
    this.getBondsData()
  },
  methods: {
    getInterestData() {
      this.isInterestDataLoading = true
      axios.get('/analytics/bonds/interest-data')
        .then(response => {
          this.interestData = response.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isInterestDataLoading = false
        })
    },
    getMonthlyInterestData() {
      this.isMonthlyInterestDataLoading = true
      axios.get(`/analytics/bonds/monthly-interest-data/${this.monthlyInterestDataYear}`)
        .then(response => {
          const { data } = response
          this.monthlyInterestData = [{ data: data.map(d => d.total_interest) }]

          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              ...this.chartOptions.xaxis,
              categories: data.map(d => this.getMonthName(d.month)),
            },
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isMonthlyInterestDataLoading = false
        })
    },
    getMonthName(monthNumber) {
      const d = new Date()
      d.setMonth(monthNumber - 1)

      return d.toLocaleString('default', { month: 'short' })
    },
    getInterestDataYears() {
      axios.get('/analytics/bonds/interest-data-years')
        .then(response => {
          this.monthlyInterestDataYears = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getUpcomingInterestData() {
      this.isUpcomingInterestDataLoading = true
      axios.get('/analytics/bonds/upcoming-interest-data')
        .then(response => {
          this.upcomingInterestData = response.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isUpcomingInterestDataLoading = false
        })
    },
    getBondsData() {
      this.isBondsDataLoading = true
      axios.get('/analytics/bonds/bonds-data')
        .then(response => {
          this.bondsData = response.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isBondsDataLoading = false
        })
    },
    handleYearChange(year) {
      this.monthlyInterestDataYear = year
      this.getMonthlyInterestData()
    },
  },
}
</script>
