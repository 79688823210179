<template>
  <v-row class="ma-0">
    <vue-skeleton-loader
      class="mt-6"
      :width="210"
      :height="20"
    />
    <vue-skeleton-loader
      class="mt-4"
      :width="160"
      :height="40"
    />
  </v-row>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    VueSkeletonLoader,
  },

}
</script>

<style>

</style>
